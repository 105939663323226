<template>
  <div>
    <strong
      class="mx-1"
      style="
        background: -webkit-linear-gradient(#efb100, #7d3b02);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 35px;
      "
    >
      HAMRAHZAR
    </strong>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>