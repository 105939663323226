import { createApp } from "vue";
import axios from "axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// +++++++ Essential Components     +++++++ +++++++ +++++++ +++++++ +++++++ +++++++ +++++++ //
import PrPreLoaderFull from "@/components/custom/PrPreLoaderFull.vue";
// ####### END Essential Components ####### ####### ####### ####### ####### ####### ####### //

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

createApp(App)
  .use(store)
  .component("PrPreLoaderFull", PrPreLoaderFull)
  .use(router)
  .mount("#app");
