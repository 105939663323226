<template>
  <NavBar @toggle-blur="toggleBlur" />
  <div>
    <div :class="{ blurred: isBodyBlurred }"></div>
    <router-view :key="$route.fullPath" />
  </div>
  <Footer />
</template>

<script>
import NavBar from "@/components/public/NavBar.vue";
import Footer from "@/components/public/Footer.vue";

export default {
  data() {
    return {
      isBodyBlurred: false,
    };
  },
  methods: {
    toggleBlur() {
      this.isBodyBlurred = !this.isBodyBlurred;
    },
  },
  components: { NavBar, Footer },
};
</script>

<style scoped>
.sd {
  background-color: red;
}
.blurred {
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.7;
  position: absolute;
  z-index: 5;
}
</style>
