<template>
  <div class="footer-bar">
    <router-link to="/" class="footer-bar-a">
      <i class="bi bi-house"></i>
      <span class="footer-bar-span">تست</span>
    </router-link>
    <router-link to="/router" class="footer-bar-a">
      <i class="bi bi-house"></i>
      <span class="footer-bar-span">تست</span>
    </router-link>
    <router-link to="/user/dashboard" class="footer-bar-a">
      <i class="bi bi-house"></i>
      <span class="footer-bar-span">تست</span>
    </router-link>
    <label @click="openModalLayerOPtions()" class="footer-bar-a">
      <i class="bi bi-house"></i>
      <span class="footer-bar-span">لایه‌تستها</span>
    </label>
    <router-link to="/about" class="footer-bar-a">
      <i class="bi bi-house"></i>
      <span class="footer-bar-span"> تست</span>
    </router-link>
  </div>
</template>

<script>
export default {
  methods: {
    sleep: function (time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    openModalLayerOPtions() {
      $("#modal-layer-options").modal();
    },
  },
};
</script>

<style scoped>
.footer-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 98;
  background-color: #1e1f25;
  /*box-shadow: 0 -5px 10px 0 rgb(0 0 0 / 6%);*/
  border-top: 1px #9a59017d solid;
  min-height: 60px;
  display: flex;
  text-align: center;
  transition: all 350ms ease;
}

.footer-bar-a {
  color: #fff !important;
  padding-top: 12px;
  position: relative;
  flex: 1 1 auto;
}

.footer-bar-span {
  position: relative;
  z-index: 2;
  display: block;
  font-size: 10px;
  font-weight: 500 !important;
  margin-top: -3px;
  opacity: 0.7;
  font-family: "Vazirmatn";
  font-weight: bold;
}

.footer-bar-i {
  font-size: 18px;
  position: relative;
  z-index: 2;
}
</style>
