<template>
  <footer style="background-color: #272523" class="footer">
    <div class="container">
      <div class="footer__content">
        <div class="footer__item footer-item-main" data-aos="fade-up">
          <PrTextLogo />
          <p class="fb-lg footer-item-main__desc">بهترین همراه طلای شما.</p>
          <div class="footer-item-main__socmed">
            <a href="#" class="footer-socmed">
              <img
                class="footer-item-main__logo"
                src="/assets/media/images/icons/logo-linkedin.svg"
                alt="Linkedin"
              />
            </a>
            <a href="#" class="footer-socmed">
              <img
                class="footer-item-main__logo"
                src="/assets/media/images/icons/logo-facebook.svg"
                alt="Facebook"
              />
            </a>
            <a href="#" class="footer-socmed">
              <img
                class="footer-item-main__logo"
                src="/assets/media/images/icons/logo-ig.svg"
                alt="Instagram"
              />
            </a>
          </div>
        </div>
        <div
          class="footer__item footer-item-quick-links"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <h6 class="footer__item-title">صفحات</h6>
          <div class="footer-quick-links">
            <a href="#" class="fb-md footer-quick-links__link"> متن نمونه</a>
            <a href="#" class="fb-md footer-quick-links__link"> متن نمونه</a>
            <a href="#" class="fb-md footer-quick-links__link"> متن نمونه</a>
            <a href="#" class="fb-md footer-quick-links__link"> متن نمونه</a>
          </div>
        </div>
        <div
          class="footer__item footer-item-updates"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <h6 class="footer__item-title">برای به‌روزرسانی ارسال کنید.</h6>
          <p class="fb-md footer-item-updates__desc">
            برای دریافت به‌روزرسانی مشترک شوید و تبادل و محصولات ما را مطلع کنید
          </p>
          <div class="mini-form-sm__box">
            <form class="mini-form">
              <input
                type="text"
                class="form-control form-control-sm mini-form__input"
                placeholder="آدرس ایمیل خود را وارد کنید"
                autocomplete="off"
              />
              <input
                type="submit"
                class="btn btn-primary btn-sm mini-form__submit"
                value="ارسال"
              />
            </form>
          </div>
        </div>
      </div>
      <div class="footer__foot" data-aos="fade-up" data-aos-delay="300">
        <p class="fb-md footer-foot__copyright">
          HAMRAHZAR ©. تمامی حقوق محفوظ است.
        </p>
        <div class="footer-foot__tos-privacy">
          <a href="#" class="fb-md footer-foot__link">شرایط خدمات</a>
          <span class="separator"></span>
          <a href="#" class="fb-md footer-foot__link">خط‌مشی رازداری</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import PrTextLogo from "@/components/custom/PrTextLogo.vue";

export default {
  components: { PrTextLogo },
};
</script>

<style lang="scss" scoped></style>
