<template>
  <nav
    id="smrtl"
    class="navbar navbar-expand-lg header-navbar header-navbar-dashboard--v1"
    data-aos="fade-down"
  >
    <div class="container container--dashboard-nav">
      <button
        class="navbar-toggler ml-auto"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="bi bi-list" style="color: #fff; font-size: 28px"></i>
      </button>
      <router-link class="navbar-brand" :to="{ name: 'PublicHome' }">
        <PrTextLogo />
      </router-link>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <div class="navbar-collapse__content">
          <ul class="navbar-nav me-auto mx-4">
            <li class="nav-item">
              <router-link
                class="nav-link active"
                :to="{ name: 'PanelUser-Dashboard' }"
              >
                داشبرد
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link"
                :to="{ name: 'PanelUser-Exchange' }"
              >
                تبادل
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link active"
                :to="{ name: 'PanelUser-Wallet' }"
              >
                کیف پول
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link active"
                :to="{ name: 'PanelUser-Profile' }"
              >
                پروفایل
              </router-link>
            </li>

            <li class="nav-item">
              <router-link
                class="nav-link active"
                :to="{ name: 'PanelUser-Logout' }"
              >
                خروج
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="navbar-right">
        <div class="navbar-notifications">
          <img
            src="/assets/media/images/icons/notifications.svg"
            alt="notifications-icon"
            class="navbar-notifications__image"
          />
          <div class="navbar-notifications__dropdown">
            <p class="fb-regular fb-regular--bold notifications__title">
              اعلان‌ها
            </p>
            <div class="notifications__box">
              <div class="notifications__item">
                <img
                  src="/assets/media/images/notification-image.png"
                  alt=""
                  class="notifications__item-image"
                />
                <div class="notifications__item-message">
                  <p class="notifications__item-sender">کیانو ریوز</p>
                  <p class="notifications__item-text">پست شما را تبلیغ کرد</p>
                </div>
              </div>
              <div class="notifications__item">
                <img
                  src="/assets/media/images/notification-image.png"
                  alt=""
                  class="notifications__item-image"
                />
                <div class="notifications__item-message">
                  <p class="notifications__item-sender">کیانو ریوز</p>
                  <p class="notifications__item-text">پست شما را تبلیغ کرد</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="navbar-profile-menu">
          <p class="navbar-profile-menu__text">کاربر پیشفرض</p>
          <img
            src="/assets/media/images/profile-photo.png"
            alt="profile-photo-icon"
            class="navbar-profile-menu__image"
          />
          <img
            src="/assets/media/images/icons/arrow-down.svg"
            alt="arrow-down-icon"
            class="navbar-profile-menu__arrow"
          />
          <div class="navbar-profile-menu__dropdown">
            <div class="navbar-profile-menu__dropdown-item">
              <a class="fb-regular dropdown-item__link" href="profile-v1.html"
                >حساب</a
              >
            </div>
            <div class="navbar-profile-menu__dropdown-item">
              <a class="fb-regular dropdown-item__link" href="sign-in-v1.html"
                >خروج از سیستم</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="navbar-search-popup">
        <input
          type="text"
          class="navbar-search-popup__input js-navbar-search-popup__input"
          placeholder="جستجو"
        />
        <img
          src="/assets/media/images/icons/search.svg"
          alt="search-icon"
          class="navbar-search-popup__image"
        />
        <img
          src="/assets/media/images/icons/plus-icon.svg"
          alt="cancel-icon"
          class="navbar-search-popup__image--cancel js-navbar-search-popup__image--cancel"
        />
      </div>
    </div>
  </nav>
</template>

<script>
import PrTextLogo from "@/components/custom/PrTextLogo.vue";

export default {
  components: {
    PrTextLogo,
  },
};
</script>

<style lang="scss" scoped>
</style>