<template>
  <nav
    class="navbar navbar-expand-lg header-navbar header-navbar--v2"
    data-aos="fade-down"
  >
    <div
      id="smrtl"
      class="container"
      style="padding-right: 0"
      :style="[
        userAgentDetails.screenYype === 'large'
          ? { direction: 'rtl' }
          : { direction: 'ltr' },
      ]"
    >
      <div class="row display-none-lg w-100 d-flex flex-row-reverse">
        <div class="col-2 d-flex justify-content-center align-items-center">
          <button
            class="navbar-toggler ml-auto"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            @click="openMenu()"
          >
            <i class="bi bi-list" style="color: #fff; font-size: 28px"></i>
          </button>
        </div>
        <div class="col-6 d-flex justify-content-start align-items-center">
          <router-link
            class="navbar-brand mt-2"
            style="margin-left: 0"
            :to="{ name: 'PublicHome' }"
          >
            <strong
              class="mx-1"
              style="
                background: -webkit-linear-gradient(#efb100, #7d3b02);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 25px;
              "
            >
              HAMRAHZAR
            </strong>
          </router-link>
        </div>

        <div class="col-4">
          <div class="navbar-buttons display-none-lg">
            <router-link
              class="btn btn-secondary btn-sm"
              style="
                color: var(--bs-gold-light);
                border-color: var(--bs-gold-light) !important;
              "
              :to="{ name: 'PanelUser-Login' }"
            >
              ورود / ثبت نام
            </router-link>
          </div>
        </div>
      </div>

      <!-- <div class="navbar-buttons display-none-lg">
        <router-link
          class="btn btn-secondary btn-sm"
          style="
            color: var(--bs-gold-light);
            border-color: var(--bs-gold-light) !important;
          "
          :to="{ name: 'PanelUser-Login' }"
        >
          ورود به سیستم
        </router-link>
      </div> -->
      <div
        class="collapse navbar-collapse nav-mobile"
        style=""
        id="navbarSupportedContent"
        :class="[userAgentDetails.screenType === 'large' ? '' : 'nav_collapse']"
      >
        <div class="navbar-collapse__content" :class="{ show: isBoxOpen }">
          <div class="navbar-buttons">
            <router-link
              class="btn btn-secondary btn-sm d-mobile-none"
              style="
                color: var(--bs-gold-light);
                border-color: var(--bs-gold-light) !important;
              "
              :to="{ name: 'PanelUser-Login' }"
            >
              ورود / ثبت نام
            </router-link>
          </div>
          <ul
            class="navbar-nav me-auto mb-2 mb-lg-0 d-cul-rev-mob padding-bottom-mobile-2"
          >
            <li
              class="nav-item"
              :class="[
                userAgentDetails.screenType === 'large' ? 'liBig' : 'liSmall',
              ]"
              v-for="(item, index) in urls"
              :key="'li-navbar-nome' + index"
            >
              <a class="nav-link" href="#">
                {{ item.text }}
              </a>
            </li>
          </ul>
        </div>
      </div>

      <router-link
        class="navbar-brand d-mobile-none"
        :to="{ name: 'PublicHome' }"
      >
        <PrTextLogo />
      </router-link>
    </div>
  </nav>
</template>

<script>
import PrTextLogo from "@/components/custom/PrTextLogo.vue";

export default {
  components: {
    PrTextLogo,
  },
  data() {
    return {
      isBoxOpen: false,
      userAgentDetails: {
        screenType: null,
      },
      urls: [
        { text: "صفحه اصلی", urlName: "", icon: "" },
        { text: "وبلاگ", urlName: "", icon: "" },
        { text: "درباره‌ما", urlName: "", icon: "" },
        { text: "تماس با ما", urlName: "", icon: "" },
        { text: "همکاری", urlName: "", icon: "" },
        { text: "سوالات متداول", urlName: "", icon: "" },
      ],
    };
  },

  methods: {
    openMenu() {
      this.$emit("toggle-blur");
    },
    sleep: function (time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    handleUserAgentDetails() {
      this.userAgentDetails = this.$root.getUserAgentDetails();
    },
  },

  created() {
    window.addEventListener("resize", this.handleUserAgentDetails);
    this.handleUserAgentDetails();
  },

  destroyed() {
    window.removeEventListener("resize", this.handleUserAgentDetails);
  },

  mounted() {
    this.userAgentDetails = this.$root.getUserAgentDetails();
    this.urls = this.urls.reverse();
  },
};
</script>

<style lang="css" scoped>
.liBig {
  width: unset;
  text-align: unset;
}
.liSmall {
  width: 100%;
  text-align: center;
}
.nav_collapse {
  border: 1px solid #efb10054;
  border-radius: 21px;
  padding: 7px;
  box-shadow: 0px -1px 35px -14px rgba(239, 177, 0, 1);
  -webkit-box-shadow: 0px -1px 35px -14px rgba(239, 177, 0, 1);
  -moz-box-shadow: 0px -1px 35px -14px rgba(239, 177, 0, 1);
}
.nav-mobile {
  position: unset;
  width: unset;
  background-color: unset;
  z-index: unset;
  top: unset;
  right: unset;
}

@media all and (min-width: 992px) {
  .display-none-lg {
    display: none !important;
  }
}

@media all and (max-width: 1023px) {
  .padding-bottom-mobile-2 {
    padding-bottom: 3rem !important;
  }
  .d-cul-rev-mob {
    display: flex;
    flex-direction: column-reverse !important;
  }
  .d-mobile-none {
    display: none !important;
  }
  .nav-mobile {
    position: absolute;
    width: 100%;
    background-color: black;
    z-index: 5555;
    top: 90px;
    right: -3px;
  }
}
</style>
